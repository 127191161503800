"use client";

import { Button } from "@/components/ui/button";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { signInWithGoogle } from "@aether/shared/lib/firebase/firebase.client";

export const LoginRedirects = () => {
  const router = useRouter();
  const onGoogleProviderSignIn = async () => {
    try {
      await signInWithGoogle();
      router.push("/collections");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <section className={"flex flex-col gap-2"}>
      <Button
        className="relative w-full"
        type="button"
        variant="outline"
        onClick={() => void onGoogleProviderSignIn()}
      >
        <Image
          alt="google login"
          className="absolute left-6 h-5 w-5"
          height={48}
          src="/icons/google.svg"
          width={48}
        />
        <span>Continue with Google</span>
      </Button>
      <Button className="relative w-full" type="button" variant="outline">
        <Image
          alt="google login"
          className="absolute left-6 h-5 w-5"
          height={48}
          src="/icons/facebook.svg"
          width={48}
        />
        <span>Continue with Facebook</span>
      </Button>

      <small className="mt-5 text-center">
        Don&lsquo;t have an account? Sign up now!
      </small>
      <Button asChild>
        <Link href="/register">Register</Link>
      </Button>
    </section>
  );
};

export const GoogleLogin = ({ url }: { url?: string }) => {
  const router = useRouter();
  const onGoogleProviderSignIn = async () => {
    try {
      const res = await signInWithGoogle();
      router.push(url ?? "/collections");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Button
      className="relative w-fit h-24 flex flex-col gap-2 p-4"
      type="button"
      variant="outline"
      onClick={() => void onGoogleProviderSignIn()}
    >
      <Image
        alt="google login"
        className="h-12 w-12"
        height={100}
        src="/icons/google.svg"
        width={100}
      />
      <div>Sign in with Google</div>
    </Button>
  );
};
