import Link from "next/link";
import Image from "next/image";

export const Logo = () => {
  return (
    <div>
      <Link
        href="/"
        className="font-bold px-2 flex items-center gap-1 text-aether-blue"
      >
        <Image
          src="/aether-logo-black.png"
          alt="Aether Logo"
          width={400}
          height={400}
          className="h-11 w-11 p-1"
        />
        <div>
          <Image
            src="/aether-logo-text.png"
            alt="Aether Logo"
            width={400}
            height={400}
            className="h-5 w-auto"
          />
          <div className="text-zinc-800 text-xs text-center leading-none border-dashed border-t border-aether-blue/75">
            organizer
          </div>
        </div>
      </Link>
    </div>
  );
};
