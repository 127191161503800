"use client";
import { LogInIcon, LogOutIcon, User } from "lucide-react";
import { useAuth } from "@aether/shared/context/auth";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import Link from "next/link";
import { Button } from "../ui/button";

export default function AuthButton() {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return <div className="h-7 w-7 animate-pulse rounded-full bg-gray-200" />;
  }

  if (!user) {
    return <div className="flex items-center gap-2 p-1.5">
      <Link href="/login" className="text-sm">
        Login
      </Link>
      <Link href="/register">
        <Button className="bg-aether-blue hover:bg-aether-blue/90">
          Register
        </Button>
      </Link>
    </div>;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="overflow-hidden rounded-full">
        <Avatar className={"w-7 h-7"}>
          <AvatarImage
            src={user.userData.photoURL ?? ""}
            alt={user.userData.displayName ?? "User Avatar"}
          />
          <AvatarFallback>
            <User className="p-1 text-zinc-500" />
          </AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="mx-1 w-40">
        <DropdownMenuItem>
          <Link href="/account/profile" className="flex gap-2">
            <User />
            <p>Profile</p>
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem className="text-destructive">
          <Link href="/logout" className="flex gap-2">
            <LogOutIcon />
            <p>Logout</p>
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
