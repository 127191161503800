"use client";
import { firebase } from "@aether/shared/lib/firebase/firebase.client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthContextProvider } from "@aether/shared/context/auth";
import Header from "@/components/organisms/header";
import Footer from "@/components/organisms/footer";

interface ClientWrapperInterface {
  children?: React.ReactNode;
}

const queryClient = new QueryClient();

export const ClientWrapper: React.FC<ClientWrapperInterface> = ({
  children,
}) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider firebase={firebase}>
        <Header />
        <hr />
        <div className="min-h-screen">
          {children}
        </div>
        <Footer />
      </AuthContextProvider>
    </QueryClientProvider>
  );
};
