import { cn } from "@/lib/utils";
import { Logo } from "@/components/atoms/logo";
import AuthButton from "@/components/molecules/auth-button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "../ui/dialog";
import { Gem } from "lucide-react";
import { Separator } from "../ui/separator";
import { Skeleton } from "../ui/skeleton";
import useWallet from "@/hooks/use-wallet";
import { Button } from "../ui/button";
import { useAuth } from "@aether/shared/context/auth";
import { getUserTokenFromCookie } from "@aether/shared/services/util-service";
import { tokenConversionRequest } from "@aether/shared/services/payment-service";
import { toast } from "sonner";
import { useState } from "react";


export function Token() {
  const {
    fetchWallet: { data, isLoading: isWalletTokenLoading },
  } = useWallet();

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false);

  return <Dialog open={open} onOpenChange={setOpen}>
    <DialogTrigger className="p-0 my-0">
      <div className="hidden sm:flex items-center rounded-lg border divide-x p-0 bg-white">
        <div className="flex items-center justify-center p-1.5">
          <Gem className="text-green-600 h-6 w-6" />
        </div>
        <Separator orientation="vertical" className="h-full" />
        {
          isWalletTokenLoading || !data ? <Skeleton className=" h-5 w-[2ch] bg-white" /> : <p className="font-semibold text-sm p-1.5">
            {data.token.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}
          </p>
        }
      </div>

      <div className="flex sm:hidden items-center rounded-lg border divide-x p-0 bg-white">
        {
          isWalletTokenLoading || !data ? <Skeleton className=" h-5 w-[2ch] bg-white" /> : <p className="text-sm p-1.5">
            {data.token.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}
          </p>
        }
        <Separator orientation="vertical" className="h-full" />
        <div className="flex items-center justify-center p-1.5">
          <Gem className="text-green-600 h-6 w-6" />
        </div>
      </div>
    </DialogTrigger>
    <DialogContent className="max-w-xs p-0 overflow-hidden gap-0 rounded-lg">
      <DialogHeader className="p-3 bg-stone-100 border-b-1 m-0">
        <DialogTitle className="font-semibold">
          AetherLenz Token
        </DialogTitle>
      </DialogHeader>
      <div className="p-3 grid  gap-2">
        <div className="flex items-center gap-1">
          <p className="text-xs text-center">
            You currently have
          </p>
          <span className="flex items-center gap-0.5 text-sm justify-center">
            {data?.token.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })}
            <Gem className="text-green-500 h-4 w-4" />
          </span>
          <p className="text-xs text-center">
            convertible tokens.
          </p>
        </div>
        <hr />
        <div className="flex p-2 justify-center">
          <Button className="bg-aether-blue hover:bg-aether-blue/90" disabled={(!data || data.token <= 0) || loading} onClick={async () => {
            try {
              setLoading(true)
              await tokenConversionRequest(getUserTokenFromCookie())
              setOpen(false)
              toast.success("A request for token conversion has been sent to the AetherLenz team.")
            } catch (e) {
              toast.error("Failed to request token conversion")
            } finally {
              setLoading(false)
            }
          }}>
            Request for token conversion
          </Button>
        </div>
      </div>
    </DialogContent>
  </Dialog >;
}

export default function Header() {
  const { user } = useAuth();
  return (
    <header
      className={cn("container w-full h-14 flex items-center justify-between")}
    >
      <Logo />
      {/*<NavigationBar />*/}
      <div className="flex gap-2">
        {user ? <Token /> : null}
        <AuthButton />
      </div>
    </header>
  );
}
